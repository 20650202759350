import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import { Message } from "element-ui";

Vue.use(VueRouter);

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const routes = [
  {
    path: "",
    name: "Index",
    component: () => import("../views/EnterpriseStandard/Index"),
    children: [
      {
        path: "project",
        name: "project",
        redirect: "/project/projectIdent",
        meta: {
          icon: "circle",
          icon2: "policydocument",
          active: 1,
          name: "文件资料",
          role: [1,2],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "stepInformation",
            name: "stepInformation",
            meta: {
              active: 1,
              name: "",
              parent_active: 1,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/formalReview/stepInformation"
              ),
          },
          {
            path: "projectIdent",
            name: "projectIdent",
            meta: {
              active: 1,
              name: "标准管理",
              parent_active: 1,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/projectLibrary/projectIdent"
              ),
          },
          {
            path: "projectRecord",
            name: "projectRecord",
            meta: {
              active: 2,
              name: "备案项目",
              parent_active: 1,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/projectLibrary/projectRecord"
              ),
          },
        ],
      },
      {
        path: "formal",
        name: "formal",
        redirect: "/formal/formalPend",
        meta: {
          icon: "circle",
          icon2: "standard",
          active: 2,
          name: "标准管理",
          role: [1,2],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "formalPend",
            name: "formalPend",
            meta: {
              active: 2,
              name: "待处理",
              parent_active: 2,
            },
            component: () =>
              import(
                // "../views/EngeryEvaluation/centerMange/formalReview/formalPend"
                "../views/EnterpriseStandard/Common/formalPend"
              ),
          },
          {
            path: "proDetailForZhongxin",
            name: "proDetailForZhongxin",
            meta: {
              active: 2,
              name: "",
              parent_active: 2,
              hidden: true,
            },
            component: () =>
              import(
                // "../views/EnterpriseStandard/Managers/managerDetail"
                "../views/EnterpriseStandard/Common/Detail"
              ),
          },
          {
            path: "proDetailDownLoad",
            name: "proDetailDownLoad",
            meta: {
              active: 2,
              name: "",
              parent_active: 2,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/formalReview/proDetailDownLoad"
              ),
          },
          {
            path: "formDetail",
            name: "formDetail",
            meta: {
              active: 2,
              name: "",
              parent_active: 2,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/formalReview/formDetail"
              ),
          },
          {
            path: "totalTable",
            name: "totalTable",
            meta: {
              active: 2,
              name: "",
              parent_active: 2,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/formalReview/totalTable"
              ),
          },
          {
            path: "ipendTable",
            name: "ipendTable",
            meta: {
              active: 2,
              name: "",
              parent_active: 2,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/formalReview/ipendTable"
              ),
          },
          {
            path: "reportTable",
            name: "reportTable",
            meta: {
              active: 2,
              name: "",
              parent_active: 2,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/formalReview/reportTable"
              ),
          },
          {
            path: "formalDistri",
            name: "formalDistri",
            meta: {
              active: 1,
              name: "审查分配",
              parent_active: 2,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/formalReview/formalDistri"
              ),
          },
        ],
      },
      // {
      //   path: "preliminary",
      //   name: "preliminary",
      //   redirect: "/preliminary/preliminaryExam",
      //   meta: {
      //     icon: "circle",
      //     icon2: "startCheck",
      //     active: 3,
      //     name: "形式检查",
      //     role: [1],
      //   },
      //   component: () =>
      //     import("../views/EngeryEvaluation/components/second_layout"),
      //   children: [
      //     {
      //       path: "preliminaryExam",
      //       name: "preliminaryExam",
      //       meta: {
      //         active: 1,
      //         // name: '初稿审查',
      //         parent_active: 3,
      //       },
      //       component: () =>
      //         import("../views/EnterpriseStandard/Managers/standardList"),
      //     },
      //     {
      //       path: "Draftreview",
      //       name: "/preliminary/Draftreview",
      //       meta: {
      //         active: 2,
      //         parent_active: 3,
      //         role: [1],
      //       },
      //       component: () =>
      //         import("../views/EnterpriseStandard/Managers/Detail"),
      //     },
      //   ],
      // },
      {
        path: "technology",
        name: "technology",
        redirect: "/technology/technicalIndex",
        meta: {
          icon: "circle",
          icon2: "expertReview",
          active: 4,
          name: "专家初审",
          role: [1,2],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "technicalIndex",
            name: "technicalIndex",
            meta: {
              active: 1,
              name: "专家初审",
              parent_active: 4,
            },
            component: () =>
              import("../views/EnterpriseStandard/Managers/standardList"),
          },
          {
            path: "Draftreview",
            name: "/technology/Draftreview",
            meta: {
              active: 2,
              parent_active: 4,
              // name: '初稿审查',
              role: [1],
            },
            component: () =>
              import("../views/EnterpriseStandard/Managers/Detail"),
          },
        ],
      },
      {
        path: "certification",
        name: "certification",
        redirect: "/certification/certificationPublicity",
        meta: {
          icon: "circle",
          icon2: "prePublicity",
          active: 5,
          name: "认证前公示",
          role: [1],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "certificationPublicity",
            name: "certificationPublicity",
            meta: {
              active: 1,
              name: "公示列表",
              parent_active: 5,
            },
            component: () =>
              import("../views/EnterpriseStandard/Managers/standardList"),
          },
          {
            path: "Draftreview",
            name: "/certification/Draftreview",
            meta: {
              active: 2,
              parent_active: 5,
              role: [1],
            },
            component: () =>
              import("../views/EnterpriseStandard/Managers/Detail"),
          },
        ],
      },
      {
        path: "spotcheck",
        name: "spotcheck",
        redirect: "/spotcheck/spotCertification",
        meta: {
          icon: "circle",
          icon2: "verification",
          active: 6,
          name: "抽检验证",
          role: [1],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "spotCertification",
            name: "spotCertification",
            meta: {
              active: 1,
              name: "认证列表",
              parent_active: 6,
            },
            component: () =>
              import("../views/EnterpriseStandard/Managers/standardList"),
          },
          {
            path: "Draftreview",
            name: "/spotcheck/Draftreview",
            meta: {
              active: 2,
              parent_active: 6,
              role: [1],
            },
            component: () =>
              import("../views/EnterpriseStandard/Managers/Detail"),
          },
        ],
      },
      {
        path: "experts",
        name: "experts",
        redirect: "/experts/expertCertification",
        meta: {
          icon: "circle",
          icon2: "expertcertification",
          active: 7,
          name: "专家认证",
          role: [1],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "expertCertification",
            name: "expertCertification",
            meta: {
              active: 1,
              name: "专家认证列表",
              parent_active: 7,
            },
            component: () =>
              import(
                // "../views/EngeryEvaluation/centerMange/technicalReview/expertCertification"
                "../views/EnterpriseStandard/Managers/standardList"
              ),
          },
          {
            path: "Draftreview",
            name: "/experts/Draftreview",
            meta: {
              active: 2,
              parent_active: 7,
              role: [1],
            },
            component: () =>
              import("../views/EnterpriseStandard/Managers/Detail"),
          },
        ],
      },
      {
        path: "standard",
        name: "standard",
        redirect: "/standard/standardRelease",
        meta: {
          icon: "circle",
          icon2: "release",
          active: 8,
          name: "公告",
          role: [1],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "standardRelease",
            name: "standardRelease",
            meta: {
              active: 8,
              name: "公示列表",
              parent_active: 8,
            },
            component: () =>
              import("../views/EnterpriseStandard/Managers/standardRelease"),
          },
        ],
      },
      {
        path: "docus",
        name: "docus",
        redirect: "/docus/instrument",
        meta: {
          icon: "circle",
          icon2: "standardtxt",
          active: 9,
          name: "标准文本",
          role: [1],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "instrument",
            name: "instrument",
            meta: {
              active: 9,
              name: "标准文件列表",
              parent_active: 9,
            },
            component: () =>
              import(
                "../views/EnterpriseStandard/Managers/components/standardFile"
              ),
          },
        ],
      },
      {
        path: "user",
        name: "user",
        redirect: "/user/accontimlist",
        meta: {
          icon: "circle",
          icon2: "user",
          active: 10,
          name: "系统设置",
          role: [1],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "enchildmanage",
            name: "/user/enchildmanage",
            meta: {
              active: 1,
              name: "个人资料",
              parent_active: 10,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/enterpriseMange/enteraccount/enchildmanage"
              ),
          },
          {
            path: "accontimlist",
            name: "accontimlist",
            meta: {
              active: 1,
              name: "账号信息列表",
              parent_active: 10,
            },
            component: () =>
              import(
                // "../views/EngeryEvaluation/centerMange/caccount/accontimlist"
                "../views/EnterpriseStandard/Managers/accountList"
              ),
          },
          {
            path: "accontimInfo",
            name: "accontimInfo",
            meta: {
              active: 1,
              name: "账号信息",
              parent_active: 10,
            },
            component: () =>
              import(
                // "../views/EngeryEvaluation/centerMange/caccount/accontimInfo"
                "../views/EnterpriseStandard/Managers/accountInfo"
              ),
          },
          {
            path: "qiyeuserList",
            name: "qiyeuserList",
            meta: {
              active: 2,
              name: "企业用户",
              parent_active: 10,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/caccount/qiyeuserList"
              ),
          },
          {
            path: "qiyeuser",
            name: "qiyeuser",
            meta: {
              active: 2,
              name: "企业用户",
              parent_active: 10,
              hidden: true,
            },
            component: () =>
              import("../views/EngeryEvaluation/centerMange/caccount/qiyeuser"),
          },
          {
            path: "exportuser",
            name: "exportuser",
            meta: {
              active: 3,
              name: "专家用户",
              parent_active: 10,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/centerMange/caccount/exportuser"
              ),
          },
        ],
      },

      // 企业用户登录
      {
        path: "projectforcom",
        name: "projectforcom",
        redirect: "/projectforcom/allProjectForCom",
        meta: {
          icon: "circle",
          icon2: "policydocument",
          active: 1,
          name: "文件资料",
          role: [3],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "allProjectForCom",
            name: "allProjectForCom",
            meta: {
              active: 1,
              name: "标识项目",
              parent_active: 1,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/enterpriseMange/item/allProjectForCom"
              ),
          },
          {
            path: "itemRecord",
            name: "itemRecord",
            meta: {
              active: 2,
              name: "备案项目",
              parent_active: 1,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/enterpriseMange/item/itemRecord"
              ),
          },
          {
            path: "itemRecordDetail",
            name: "itemRecordDetail",
            meta: {
              active: 2,
              name: "备案项目",
              parent_active: 2,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/enterpriseMange/item/itemRecordDetail"
              ),
          },
          {
            path: "ipImformation",
            name: "ipImformation",
            meta: {
              active: 1,
              name: "待处理",
              parent_active: 1,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/enterpriseMange/ident/ipImformation"
              ),
          },
          {
            path: "updateProject",
            name: "updateProject",
            meta: {
              active: 1,
              name: "待处理",
              parent_active: 1,
              hidden: true,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/enterpriseMange/ident/updateProject"
              ),
          },
        ],
      },
      {
        path: "user_project",
        name: "user_project",
        redirect: "/user_project/itemIdent",
        meta: {
          icon: "circle",
          icon2: "standard",
          active: 2,
          name: "标准申报",
          role: [3],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "detail",
            name: "detail",
            meta: {
              active: 2,
              name: "项目申报",
              parent_active: 3,
            },
            component: () =>
              import("../views/EnterpriseStandard/Common/Detail"),
          },
          {
            path: "itemIdent",
            name: "itemIdent",
            meta: {
              active: 1,
              name: "标准申报",
              parent_active: 2,
            },
            component: () =>
              // import(
              //   "../views/EngeryEvaluation/enterpriseMange/item/itemIdent"
              // ),
              import("../views/EnterpriseStandard/Common/formalPend"),
          },

          {
            path: "addImformation",
            name: "addImformation",
            meta: {
              active: 2,
              name: "待处理",
              parent_active: 2,
              hidden: true,
            },
            component: () =>
              import(
                // "../views/EngeryEvaluation/enterpriseMange/ident/addImformation"
                "../views/EnterpriseStandard/Companies/addImformation"
              ),
          },
        ],
      },
      {
        path: "user_user",
        name: "user_user",
        redirect: "/user_user/enchildmanage",
        meta: {
          icon: "circle",
          icon2: "user",
          active: 7,
          name: "用户管理",
          role: [2,3],
        },
        component: () =>
          import("../views/EngeryEvaluation/components/second_layout"),
        children: [
          {
            path: "enchildmanage",
            name: "enchildmanage",
            meta: {
              active: 1,
              name: "企业信息",
              parent_active: 7,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/enterpriseMange/enteraccount/enchildmanage"
              ),
          },
          {
            path: "enInformation",
            name: "enInformation",
            meta: {
              active: 2,
              name: "子账户管理",
              parent_active: 7,
            },
            component: () =>
              import(
                "../views/EngeryEvaluation/enterpriseMange/enteraccount/enInformation"
              ),
          },
        ],
      },
      
    ],
  },

  {
    path: "/login",
    name: "登录",
    component: () => import("../views/Login"),
  },
  {
    path: "/register",
    name: "注册",
    component: () => import("../views/Register"),
  },
  {
    path: "/password",
    name: "修改密码",
    component: () => import("../views/password"),
  },
];

const router = new VueRouter({
  routes,
});

//增加路由守卫
router.beforeEach((to, from, next) => {
  if (to.path == "/login" || to.path == "/register" || to.path == "/password") {
    next();
  } else {
    if (!store.state.token || store.state.token == "") {
      let user = localStorage.getItem("energy_user");
      if (user && user != "") {
        store.commit("set_user", JSON.parse(user));
      }
    }
    if (store.state.token && store.state.token != "") {
      if (to.name == "Index") {
        if (store.state.role.indexOf(1) != -1) {
          next({ path: "/project/projectIdent" });
        } else if (store.state.role.indexOf(3) != -1) {
          next("/user_project/itemIdent"); //这里企业用户进来修改为跳转到标准管理
        } else {
          next("/project/projectIdent");
        }
      } else {
        next();
      }
    } else {
      Message.warning("请先登录");
      next({ path: "/login" });
    }
  }
});

export default router;
